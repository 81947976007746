import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import UnsubscribedThankYou from "../components/UnsubscribedThankYou"

const UnsubscribedThankYouPage = () => (
  <Layout backgroundColor={"white"} navColor={"black"}>
    <SEO
      title="Thesis | Unsubscribe confirmation"
      keywords={[]}
      description="You’ve unsubscribed from the Thesis mailing list."
    />
    <UnsubscribedThankYou />
  </Layout>
)

export default UnsubscribedThankYouPage
